import { useState, useEffect } from "react";
import ConfettiExplosion from "react-confetti-explosion";
// mui
import {
  Grid,
  Typography,
  Box,
  Card,
  Avatar,
  Stack,
  Button,
} from "@mui/material";
// utils
import axios from "../utils/axios";
import Countdown from "react-countdown";

const divStyle = {
  width: "100vw",
  height: "100vh",
  backgroundImage: `url(https://i.imgur.com/jAn6d9A.png)`,
  backgroundSize: "cover",
  display: "flex",
  alignItems: "flex-start",
};

const renderer = ({ hours, minutes, seconds, completed }) => {
  return (
    <div
      className="awesome"
      style={{
        textAlign: "center",
        position: "absolute",
        top: "25%",
        width: "100vw",
        height: "100vh",
      }}
    >
      <span style={{ fontSize: "30rem", color: 'white', fontWeight: 900    }}>{seconds}</span>
    </div>
  );
};

const Details = () => {
  const [isExploding, setIsExploding] = useState(false);
  const [users, setUser] = useState([]);
  const [sorteado, setSorte] = useState({});
  const [listSorte, setLista] = useState([]);
  const [keyS, setKeyS] = useState(0);
  const [ver, setVer] = useState(false);

  const fristLoad = async () => {
    // Requests
    const usersRes = await axios.get(`/users.php?link=123`);

    if (usersRes.status) setUser(usersRes.data);
  };

  const handleSorte = () => {
    setVer(false);
    let sorteados = [...users.filter((f) => f.status === "1")];

    sorteados = [...sorteados.filter((el) => !listSorte.includes(el))];

    const winner = sorteados[parseInt(Math.random() * sorteados.length)];

    if (winner.length !== 0) {
      setLista([...listSorte, winner]);
      setSorte(winner);
      setKeyS(keyS + 1);
    }
  };
  // Effects
  useEffect(() => {
    // Initial Effect
    if (users.length === 0) fristLoad();
  }, [users, setUser]);

  useEffect(() => {
    // Initial Effect
    if (isExploding === true) {
      setTimeout(() => {
        setIsExploding(false);
      }, 2000);
    }
  }, [isExploding]);

  return (
    <>
      {isExploding && (
        <ConfettiExplosion
          force={1}
          duration={3000}
          particleCount={350}
          width={4000}
        />
      )}

      <Stack
        component="section"
        direction="column"
        justifyContent="center"
        alignItems="center"
        style={divStyle}
      >
        {!ver && listSorte.length !== 0 && (
          <Countdown
            date={Date.now() + 5000}
            key={keyS}
            onComplete={() => {
              setIsExploding(true);
              setVer(true);
            }}
            renderer={renderer}
          ></Countdown>
        )}
        {sorteado?.name !== undefined && ver && (
          <div
            style={{
              padding: 10,
              background: "rgb(0 0 0 / 40%)",
              borderRadius: 20,
              width: 1000,
              display: "flex",
              flexDirection: "column",
              alignItems: "center",

              marginLeft: 10,
            }}
          >
            <Typography
              variant="h2"
              style={{
                background: "#ff65c9",
                textAlign: "center",
                padding: 15,
                fontWeight: 900,
                borderRadius: 20,
              }}
            >
              GANHADORA
            </Typography>
            <Typography
              variant="h2"
              style={{
                textAlign: "center",
                fontWeight: 900,
                marginTop: 15,
                marginBottom: 15,
                color: "#fff",
              }}
            >
              {sorteado?.name}
            </Typography>

            <Avatar
              variant="rounded"
              src={sorteado?.image_link}
              sx={{ width: 400, height: 400 }}
            />
            <Button
              onClick={() => {
                setIsExploding(!isExploding);
              }}
            />
          </div>
        )}
        {(ver || keyS === 0) && (
          <Button
            variant={"outlined"}
            color={"secondary"}
            onClick={handleSorte}
            sx={{ mt: 10, display: "fixed" }}
          >
            Sortear !
          </Button>
        )}
      </Stack>

      {ver && (
        <div style={{ position: "absolute", top: 0, right: 20 }}>
          {listSorte.map((post, index) => (
            <Grid item xs={12} sx={{ m: 1 }} key={index}>
              <Card
                style={{
                  width: 335,
                  background: post.status === "1" ? "#95b995" : "none",
                }}
              >
                <Box
                  sx={{
                    p: 2,
                    display: "flex",
                    justifyContent: "flex-start",
                  }}
                >
                  <Avatar
                    variant="rounded"
                    src={`${post.image_link}?${new Date().getTime()}`}
                    sx={{ width: 40, height: 40 }}
                  />
                  <Stack
                    spacing={1}
                    style={{ justifyContent: "center", marginLeft: 10 }}
                  >
                    <Typography fontWeight={700} variant="body2">
                      {index + 1} - {post.name}
                    </Typography>
                  </Stack>
                </Box>
              </Card>
            </Grid>
          ))}
        </div>
      )}
    </>
  );
};

export default Details;
