import { useState, useEffect } from "react";
// mui
import Table from "@mui/material/Table";
import TableBody from "@mui/material/TableBody";
import TableCell from "@mui/material/TableCell";
import TableContainer from "@mui/material/TableContainer";
import TableHead from "@mui/material/TableHead";
import TableRow from "@mui/material/TableRow";
import Paper from "@mui/material/Paper";
import Typography from "@mui/material/Typography";
// utils
import axios from "../utils/axios";

export default function BasicTable() {
  const [users, setUser] = useState([]);

  // Effects
  useEffect(() => {
    // Initial Effect
    const fristLoad = async () => {
      // Requests
      const usersRes = await axios.get(`/users.php?link=123`);

      if (usersRes.status) setUser(usersRes.data);
    };

    if (users.length === 0) fristLoad();
  }, [users, setUser]);

  return (
    <>
      <Typography variant="h5" sx={{ mt: 2, fontWeight: 700 }}>
        Vagas Restantes
        <span
          style={{
            background: "#ff65c9",
            display: "inline-block",
            width: 50,
            textAlign: "center",
          }}
        >
          {users.length}
        </span>
      </Typography>
      <TableContainer component={Paper}>
        <Table sx={{ minWidth: 650 }} aria-label="simple table">
          <TableHead>
            <TableRow>
              <TableCell>Nome</TableCell>
              <TableCell align="right">E-mail</TableCell>
              <TableCell align="right">Telefone</TableCell>
              <TableCell align="right">Alimento</TableCell>
            </TableRow>
          </TableHead>
          <TableBody>
            {users.map((row, i) => (
              <TableRow
                key={row.name}
                sx={{ "&:last-child td, &:last-child th": { border: 0 } }}
              >
                <TableCell component="th" scope="row">
                  {i + 1} - {row.name}
                </TableCell>
                <TableCell align="right">{row.email}</TableCell>
                <TableCell align="right">{row.phone}</TableCell>
                <TableCell align="right">{row.msg}</TableCell>
              </TableRow>
            ))}
          </TableBody>
        </Table>
      </TableContainer>
    </>
  );
}
