import { useState, useEffect, useRef, useCallback } from "react";
import { maskJs } from "mask-js";

import Webcam from "react-webcam";
// mui
import {
  Grid,
  Typography,
  TextField,
  Box,
  Card,
  CardActions,
  CardContent,
  CardHeader,
  Avatar,
  Stack,
  IconButton,
  Divider,
  Chip,
  Modal,
  Button,
} from "@mui/material";
import { Edit, Phone, Fingerprint } from "@mui/icons-material";
// utils
import axios from "../utils/axios";
// components
import Title from "./Title";

const videoConstraints = {
  width: 400,
  height: 300,
  facingMode: "environment",
};

const Content = () => {
  const [users, setUser] = useState([]);
  const [query, setQuery] = useState("");
  const [open, setOpen] = useState(false);
  const [user, setUseron] = useState({});
  const [picture, setPicture] = useState("");
  const webcamRef = useRef(null);
  const capture = useCallback(() => {
    const imageSrc = webcamRef.current.getScreenshot();
    setPicture(imageSrc);
  }, [webcamRef]);

  const handleChange = (event) => {
    event.persist();

    setUseron({
      ...user,
      [event.target.name]:
        event.target.type === "checkbox"
          ? event.target.checked
          : event.target.value,
    });
  };

  const handleSubmit = async (event) => {
    let result = {};
    const config = {
      headers: {
        "content-type": "multipart/form-data",
      },
    };
    if (user.status === "0") {
      result = await axios.post(
        `/confirmation.php`,
        { data: picture, id: user.id, name: user.name },
        config
      );
    } else {
      result = await axios.post(
        `/confirmation.php`,
        {
          cancelar: "TRUE",
          id: user.id,
        },
        config
      );
    }
    if (result.status) {
      fristLoad();
      setUseron({});
      setPicture("");
      setOpen(false);
    }
  };

  const handleOpen = (e) => {
    setUseron(e);
    if (e.image_link !== "") {
      setPicture(e.image_link);
    }
    setOpen(true);
  };

  const handleClose = () => {
    setUseron({});
    setPicture("");
    setOpen(false);
  };

  const fristLoad = async () => {
    // Requests
    const usersRes = await axios.get(`/users.php?link=123`);

    if (usersRes.status) setUser(usersRes.data);
  };

  // Effects
  useEffect(() => {
    // Initial Effect

    if (users.length === 0) fristLoad();
  }, [users, setUser]);

  return (
    <>
      <Grid
        container
        spacing={0}
        sx={{
          display: "flex",
          justifyContent: "center",
          alignItems: "center",
          py: 10,
          px: 2,
          flexDirection: "column",
        }}
      >
        <Grid item xs={12} sm={12} md={5}>
          <Title text={"Lista de presença"} textAlign={"center"} />

          <Typography
            variant="h6"
            component="h4"
            style={{
              background: "#ff65c9",
              textAlign: "center",
            }}
          >
            Mulheres de posicionamento 2024
          </Typography>
          <TextField
            id="outlined-basic"
            label="Nome"
            variant="outlined"
            name="name"
            fullWidth
            sx={{ mt: 2 }}
            value={query}
            onChange={(event) => setQuery(event.target.value)}
          />
        </Grid>
        {users
          .filter(
            (f) =>
              f.name.toLowerCase().includes(query.toLowerCase()) || query === ""
          )
          .map((post, index) => (
            <Grid item xs={12} sx={{ m: 1 }} key={index}>
              <Card
                style={{
                  width: 450,
                  background: post.status === "1" ? "#95b995" : "none",
                }}
              >
                <Box
                  sx={{
                    p: 2,
                    display: "flex",
                    justifyContent: "space-between",
                  }}
                >
                  <Avatar
                    variant="rounded"
                    src={`${post.image_link}?${new Date().getTime()}`}
                    sx={{ width: 100, height: 100 }}
                  />
                  <Stack spacing={1}>
                    <Typography fontWeight={700}>{post.name}</Typography>
                    <Typography
                      variant="body2"
                      color="text.secondary"
                      sx={{ display: "flex" }}
                    >
                      <Phone sx={{ color: "#ccc" }} />
                      {maskJs("(99) 9999?9-9999", post.phone)}
                    </Typography>
                    <Typography
                      variant="body2"
                      color="text.secondary"
                      sx={{ display: "flex" }}
                    >
                      {post.email}
                    </Typography>
                  </Stack>
                  <IconButton
                    sx={{ width: 50, height: 50 }}
                    onClick={() => {
                      handleOpen(post);
                    }}
                  >
                    <Edit sx={{ fontSize: 20 }} />
                  </IconButton>
                </Box>
                <Divider />
                <Stack
                  direction="row"
                  alignItems="center"
                  justifyContent="space-between"
                  sx={{ px: 2, py: 1, bgcolor: "background" }}
                >
                  <Chip
                    color={post.status === "1" ? "success" : "default"}
                    label={
                      post.status === "1" ? "Confirmado" : "Não Confirmado"
                    }
                  />
                </Stack>
              </Card>
            </Grid>
          ))}
        <Modal
          open={open}
          onClose={handleClose}
          aria-labelledby="child-modal-title"
          aria-describedby="child-modal-description"
        >
          <Card style={{ margin: 50, position: "relative" }}>
            <CardHeader title="Contato" />
            <Divider />
            <CardContent>
              <div
                style={{
                  display: "flex",
                  alignItems: "center",
                  justifyContent: "space-evenly",
                  height: 280,
                }}
              >
                {picture === "" ? (
                  <Webcam
                    audio={false}
                    height={300}
                    ref={webcamRef}
                    screenshotFormat="image/jpeg"
                    width={400}
                    style={{ margin: 0 }}
                    videoConstraints={videoConstraints}
                  />
                ) : (
                  <Avatar
                    variant="rounded"
                    src={`${picture}`}
                    sx={{ width: 300, height: 300 }}
                  />
                )}
                {picture !== "" ? (
                  <IconButton
                    aria-label="fingerprint"
                    color="success"
                    onClick={(e) => {
                      e.preventDefault();
                      setPicture("");
                    }}
                    sx={{ width: 100, height: 100 }}
                  >
                    <Fingerprint sx={{ width: 50, height: 50 }} />
                  </IconButton>
                ) : (
                  <IconButton
                    aria-label="fingerprint"
                    color="success"
                    onClick={capture}
                    sx={{ width: 100, height: 100 }}
                  >
                    <Fingerprint sx={{ width: 50, height: 50 }} />
                  </IconButton>
                )}
              </div>
              <Grid container spacing={4} sx={{ mt: 2 }}>
                <Grid item md={6} xs={12}>
                  <TextField
                    fullWidth
                    label="Nome"
                    name="name"
                    onChange={handleChange}
                    required
                    value={user.name}
                    variant="outlined"
                  />
                </Grid>

                <Grid item md={6} xs={12}>
                  <TextField
                    fullWidth
                    label="E-Mail"
                    name="email"
                    onChange={handleChange}
                    value={user.email}
                    variant="outlined"
                  />
                </Grid>

                <Grid item xs={6} sm={3}>
                  <TextField
                    fullWidth
                    label="Telefone"
                    name="phone"
                    onChange={handleChange}
                    required
                    value={user.phone}
                    variant="outlined"
                  />
                </Grid>

                <Grid item md={9} xs={12}>
                  <TextField
                    fullWidth
                    label="Notas"
                    name="note"
                    onChange={handleChange}
                    multiline
                    rows="4"
                    value={user.msg}
                    variant="outlined"
                  />
                </Grid>
              </Grid>
            </CardContent>
            <Divider />
            <CardActions>
              <Button
                variant="contained"
                color={user.status === "0" ? "success" : "error"}
                disabled={picture === ""}
                onClick={handleSubmit}
              >
                {user.status === "0" ? "Confirmar" : "Cancelar"} Presença
              </Button>
              <Button variant="contained" color="inherit" onClick={handleClose}>
                Voltar
              </Button>
            </CardActions>
          </Card>
        </Modal>
      </Grid>
    </>
  );
};

export default Content;
