import { useState, useEffect } from "react";
import * as Yup from "yup";
// form
import { yupResolver } from "@hookform/resolvers/yup";
import { useForm } from "react-hook-form";
// @mui
import { Box, Button, styled, Typography, Grid, Stack } from "@mui/material";
import { LoadingButton } from "@mui/lab";
// components
import { FormProvider, RHFTextField } from "../components/hook-form";
// utils
import axios from "../utils/axios";
// img
import headerImg from "../assets/temas.png";
import logoImg from "../assets/logos.png";
import bgImg from "../assets/bgg.jpeg";

const Header = () => {
  const [users, setUser] = useState(null);
  const [obrgiado, setObg] = useState(false);
  const [erro, setErro] = useState(false);
  const [usSave, setusSave] = useState({});

  const CustomBox = styled(Box)(({ theme }) => ({
    minHeight: "100vh",
    display: "flex",
    justifyContent: "center",
    // tamanhos
    gap: theme.spacing(2),
    paddingTop: theme.spacing(10),
    // cor de fundo
    backgroundRepeat: "no-repeat",
    backgroundSize: "auto",
    backgroundColor: "#fafafc",
    background: `url(${bgImg})`,
    [theme.breakpoints.down("md")]: {
      flexDirection: "column",
      alignItems: "center",
      textAlign: "center",
    },
  }));

  const BoxText = styled(Box)(({ theme }) => ({
    flex: "1",
    paddingLeft: theme.spacing(8),
    [theme.breakpoints.down("md")]: {
      flex: "2",
      textAlign: "center",
      paddingLeft: theme.spacing(2),
      paddingRight: theme.spacing(2),
    },
  }));

  const TroncosSchema = Yup.object().shape({
    name: Yup.string()
      .min(5, "Minimo 5 caracteres")
      .required("Campo obrigatorio"),
    email: Yup.string().email("Email invalido").required("Campo obrigatorio"),
    phone: Yup.number()
      .min(10, "Minimo 10 digitos")
      .typeError("Telefone precisar ter somente numero digitados")
      .required("Campo obrigatorio"),
  });

  const defaultValues = {
    name: "",
    email: "",
    phone: "",
    msg: "",
  };

  const methods = useForm({
    resolver: yupResolver(TroncosSchema),
    defaultValues,
  });

  const {
    handleSubmit,
    formState: { isSubmitting },
  } = methods;

  const onSubmit = async (data) => {
    // Add Labels to object will send
    const userRes = await axios.post(`/save.php`, data);
    if (userRes.data.success) {
      setusSave(data);
      setObg(true);
    } else {
      setErro(true);
    }
  };

  // Effects
  useEffect(() => {
    // Initial Effect
    const fristLoad = async () => {
      // Requests
      const usersRes = await axios.get(`/users.php`);

      if (usersRes.status) setUser(usersRes.data);
    };

    if (users === null) fristLoad();
  }, [users, setUser]);

  if (users?.vagas >= 1) {
    return (
      <CustomBox component="header">
        {/*  Box text  */}
        <BoxText component="section">
          <img src={logoImg} style={{ width: "100%" }} alt={"logo"} />

          {!obrgiado && !erro && (
            <>
              {/* <Typography variant="h5" sx={{ mt: 2, fontWeight: 700 }}>
                Vagas Restantes
                <span
                  style={{
                    background: "#ff65c9",
                    display: "inline-block",
                    width: 50,
                    textAlign: "center",
                  }}
                >
                  {users?.vagas}
                </span>
              </Typography> */}
              <Typography variant="h5" sx={{ mt: 2, fontWeight: 700 }}>
                Restam poucas vagas
              </Typography>
              <Box>
                <FormProvider
                  methods={methods}
                  onSubmit={handleSubmit(onSubmit)}
                >
                  <Grid container spacing={3} mt={2}>
                    <Grid item xs={12}>
                      <Stack spacing={3}>
                        <RHFTextField
                          name="name"
                          label="Nome completo"
                          variant="filled"
                        />
                      </Stack>
                    </Grid>
                    <Grid item xs={12}>
                      <Stack spacing={3}>
                        <RHFTextField
                          name="email"
                          label="Melhor Email"
                          variant="filled"
                        />
                      </Stack>
                    </Grid>
                    <Grid item xs={12}>
                      <Stack spacing={3}>
                        <RHFTextField
                          name="phone"
                          label="Telefone"
                          variant="filled"
                        />
                      </Stack>
                    </Grid>
                  </Grid>
                  <Typography variant="h5" sx={{ mt: 2, fontWeight: 700 }}>
                    Endereço
                  </Typography>
                  <Typography variant="body2" sx={{ mt: 2 }}>
                    Av. Ibirapuera, 2315 - MoemaSão Paulo - SP, 04029-200{" "}
                    <br></br> (Ao lado da estação de Moema)
                  </Typography>

                  <Box sx={{ display: "flex", flexDirection: "row", pt: 2 }}>
                    <LoadingButton
                      fullWidth
                      type="submit"
                      variant="contained"
                      size="large"
                      loading={isSubmitting}
                      sx={{ m: 1 }}
                      color="secondary"
                    >
                      Inscrever-se
                    </LoadingButton>
                  </Box>
                </FormProvider>
              </Box>
            </>
          )}
          {obrgiado && !erro && (
            <Box>
              <Typography variant="h5" sx={{ mt: 2, fontWeight: 700 }}>
                Olá {usSave?.name}
              </Typography>
              <Typography variant="body1" sx={{ mt: 2 }}>
                Obrigado por se inscrever no nosso evento Mulheres de
                Posicionamento. Estamos muito felizes em ter você conosco!
              </Typography>
              <Typography variant="body1" sx={{ mt: 2 }}>
                Em breve entraremos em contato com você com mais informações
                sobre o evento, mas, enquanto isso, você pode acompanhar nossas
                redes sociais para saber mais sobre o que vai acontecer.
              </Typography>
              <Typography variant="body1" sx={{ mt: 2 }}>
                Estamos ansiosos para vê-lo lá!
              </Typography>
              <Typography variant="body1" sx={{ mt: 2 }}>
                Atenciosamente
              </Typography>
              <Typography variant="body1">
                Pra. Bruna Mokfa - Igreja Agape Church
              </Typography>
              <Typography variant="h5" sx={{ mt: 2, fontWeight: 700 }}>
                Endereço
              </Typography>
              <Typography variant="body2" sx={{ mt: 2 }}>
                Av. Ibirapuera, 2315 - MoemaSão Paulo - SP, 04029-200 <br></br>{" "}
                (Ao lado da estação de Moema)
              </Typography>
              <Button
                color="secondary"
                href="https://www.instagram.com/mulheres_de_posicionamento/"
                sx={{ mt: 2 }}
              >
                Acesse o Instagram
              </Button>
            </Box>
          )}

          {erro && (
            <Box>
              <Typography variant="h5" sx={{ mt: 2, fontWeight: 700 }}>
                Olá {usSave?.name}
              </Typography>
              <Typography variant="body1" sx={{ mt: 2 }}>
                Lamentamos informar que houve um erro ao cadastrar você em nosso
                sistema. Por favor, tente novamente mais tarde.
              </Typography>
              <Typography variant="body1" sx={{ mt: 2 }}>
                Se você continuar tendo problemas, entre em contato conosco pelo
                instagram
              </Typography>
              <Typography variant="body1" sx={{ mt: 2 }}>
                Agradecemos sua compreensão.
              </Typography>
              <Typography variant="body1" sx={{ mt: 2 }}>
                Atenciosamente
              </Typography>
              <Typography variant="body1">
                Pra. Bruna Mokfa - Igreja Agape Church
              </Typography>
              <Button
                color="secondary"
                href="https://www.instagram.com/mulheres_de_posicionamento/"
                sx={{ mt: 2 }}
              >
                Acesse o Instagram
              </Button>
            </Box>
          )}
        </BoxText>

        <Box
          sx={(theme) => ({
            [theme.breakpoints.down("md")]: {
              flex: "1",
              paddingTop: "1px",
              alignSelf: "center",
            },
            [theme.breakpoints.up("md")]: {
              flex: "2",
              alignSelf: "flex-start",
            },
          })}
        >
          <img
            src={headerImg}
            alt="headerImg"
            style={{
              width: "70%",
              marginTop: -20,
            }}
          />
        </Box>
      </CustomBox>
    );
  }

  if (users?.vagas <= 0) {
    return (
      <>
        <CustomBox component="header">
          {/*  Box text  */}
          <BoxText component="section">
            <img src={logoImg} style={{ width: "70%" }} alt={"logo"} />
            <Typography variant="h3" sx={{ mt: 3, fontWeight: 700 }}>
              Vagas Esgotadas
            </Typography>
            <Typography variant="body1" sx={{ mt: 2 }}>
              Lamentamos informar que as vagas para o nosso evento Mulheres de
              Posicionamento estão esgotadas. Recebemos uma resposta muito
              positiva à nossa campanha e estamos muito felizes em ver que
              tantas pessoas estão interessadas em participar.
            </Typography>
            <Typography variant="body1" sx={{ mt: 2 }}>
              Estamos trabalhando para aumentar a capacidade do evento, mas por
              enquanto não temos vagas disponíveis. Se você estiver interessado
              em participar, pedimos que você se inscreva em nossa lista de
              espera. Assim que tivermos mais vagas disponíveis, entraremos em
              contato com você.
            </Typography>
            <Typography variant="body1" sx={{ mt: 2 }}>
              Agradecemos sua compreensão e esperamos vê-lo no nosso próximo
              evento!
            </Typography>
            <Typography variant="body1" sx={{ mt: 2 }}>
              Atenciosamente, Pra. Bruna Mokfa - Igreja Agape Church
            </Typography>
          </BoxText>
        </CustomBox>
      </>
    );
  }
};

export default Header;
